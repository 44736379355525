<template>
  <div class="d-flex flex-column align-items-center mt-2 vue-container col-12">
    <img @click="this.$router.push('/')" class="back-arrow" src="../../assets/back-btn.png">
    <div class=" d-flex flex-column text-center align-items-center header-text mt-5 col-10">
      TERMS & CONDITIONS
    </div>
    <img class="game-logo col-6" src="../../assets/gameLogos/quiz-inc-logo.png">
    <img @click="playNowClicked()" class="col-5" src="../../assets/play-now-btn.png">
    <div class="mt-2 col-9 info-text">
      WELCOME TO THE QUIZINC TERMS AND CONDITIONS PAGE. PLEASE SELECT THE DROP DOWN THAT SUITS YOUR PURPOSES BELOW.
    </div>
    <div class="mt-2 header-text">
      QUIZINC
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(0)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">SERVICE TERMS</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(0)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(0)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text col-11 mt-2" v-if="isDropdownOpen(0)">
        <div class="accordion">
          <div class="accordion-content">
            <p>
              1. These terms and conditions govern the relationship between You (“You” or “Your”) and Vodacom
              Proprietary
              Limited and its affiliates (hereinafter “Vodacom” or “Us” or “We”) and Fanclash South Africa Pty Ltd
              (“Partner”) regarding Your use of the Quizinc service and related features (the “Service”)
              (“Terms and Conditions”). Use of the Service is also governed by the
              <a href="https://www.vodacom.co.za/vodacom/privacy-policy/terms">Privacy Policy</a>
              which is incorporated herein by reference.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            Important provision: Acceptance of these terms and conditions
          </p>
          <div class="accordion-content">
            <p>
              2. Please read these terms and conditions and the
              <a href="https://www.vodacom.co.za/vodacom/privacy-policy/terms">Privacy Policy</a>
              carefully before using the service or
              Submitting information through the service. By using the service or submitting information through
              The service, You are agreeing to these terms and conditions. If You do not agree, please do not use
              This service or submit any information through the service. We reserve the right, at any time, to
              Modify and update these terms and conditions from time to time by posting such updated terms and
              Conditions on the Vodacom website. Any such updates shall apply to You and Your use of the service
              And by continuing to use the service, You shall be deemed to have accepted such changes.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            SUBSCRIBE OR UNSUBSCRIBE TO THE SERVICE?
          </p>
          <div class="accordion-content">
            <p>
              2.1 In order to subscribe the Service You must:<br>
              2.1.1. dial USSD: *117*77#; or
              visit web: <a href="www.quizinc.co.za">www.quizinc.co.za</a>.
            </p>
            <p>
              2.2 You can also unsubscribe from the service by:<br>
              2.2.1 calling Vodacom Customer Care: 082 135 <br>
              2.2.2 following the “cancel” prompts available within the Service menu, <br>
              2.2.3 using Vodacom self-service channels <br>
              2.2.3 dialing USSD: *135*997# <br>
              2.2.4 visiting WEB: <a href="www.Quizinc.co.za/">www.Quizinc.co.za/ </a><br>
            </p>
            <p>
              2.2.3 Dial *135*997# (Vodacom Global Opt Out)
            </p>
            <p>
              2.2.4 View Content Services on the My Vodacom App
            </p>
            <p>
              2.3 When canceling / unsubscribing from the Service, you accept that your old profile and preferences
              will be retained for a 6 (six) month period and for no longer than 5 years (only if necessary),
              in order to facilitate a convenient experience should you choose to re-subscribe at a later stage
              within the 6 (six) month period.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            General
          </p>
          <div class="accordion-content">
            <p>
              2.4 As a user of the Service, You will have the option to subscribe on a recurring basis with either
              scenario making You a paying user and doing so accordingly binds You to these Terms and Conditions.
              As a subscribed user, You will be asked to submit personal information to us. We are committed to
              ensuring responsible practices in the collection, use and disclosure of Your personal information
              in accordance with applicable law and the
              <a href="https://www.vodacom.co.za/vodacom/privacy-policy/terms">Privacy Policy</a>
              incorporated by reference into and form a binding part of these Terms and Conditions.
            </p>
            <p>
              2.5. By subscribing to the Service, You agree to the following additional rules upon successful initial
              registration for the Service:
            </p>
            <p>
              2.5.1 You will be eligible to access all the content on the Service free of charge for 1 (one) day
              (the “Free Trial Day”). For the avoidance of doubt, You will not be eligible for another Free Trial
              Day if You cancel Your subscription and then re-register at a later stage.<br>
              After the Free Trial Day, You will be charged a subscription fee of ZAR 3.00 per day (incl. VAT)
              (the “Fee”) for the relevant period that You remain subscribed for the Service.
            </p>
            <p>
              2.6 If You are a pre-paid or top-up user:<br>
            </p>
            <p>
              2.6.1 the Fee will be deducted from Your available airtime balance depending on the subscription that
              You have chosen. You acknowledge that You must have enough airtime available to pay the Fee to
              continue to use the Service;<br>
              if You fail to satisfy Your Fee obligation for 60 (sixty) consecutive days after the last successful
              Fee was collected, Vodacom may at its sole discretion suspend or terminate Your subscription to the
              Service;
            </p>
            <p>
              2.6.2 the Fee for each day of the month that You were subscribed to the Service for will be set out in
              Your monthly statement for payment. If You do not pay Your monthly fee set out in Your monthly statement,
              Your subscription may be suspended;<br>
              before the expiry of the Free Trial Day, You may cancel Your subscription to the Service to avoid being
              charged the Fee.
            </p>
            <p>
              2.6.3 only one subscription is permitted per mobile number. Vodacom and non-Vodacom (where they can do so)
              users within the Republic of South Africa may subscribe.
            </p>
            <p>
              2.7 If You are a contract user, the Fee will be set out in Your monthly statement. If You do not pay
              Your monthly Fee, Your subscription may be suspended by Vodacom in its discretion.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            Micro-Billing
          </p>
          <div class="accordion-content">
            <p>
              2.8 If Vodacom are unable to bill the full value of the subscription You have chosen for the Service,
              we may implement micro-billing. Micro billing occurs when You have subscribed to Service and You do
              not have sufficient funds in Your account (i.e., Your airtime balance) to pay for the subscription
              that You have chosen. Micro-billing will allow Vodacom to charge a discounted subscription or any
              reduced amount as we may determine from time to time dependent on the available balance until we
              can charge You the full amount. If we are successful in billing an amount, in part of in full for
              the end of the applicable period (depending on the subscription chosen), that billing record will
              stand as the completed billing for that particular period. Billing for the next period will revert back
              to attempting to bill the full stated price. Billing does not occur in arrears. Micro-billing shall
              be implemented in Vodacom’s sole and absolute discretion. You must have enough airtime available for
              payment of the subscription You have chosen for the Service to keep Your subscription in an active state.
              You acknowledge that in the event we are unable to bill any valid value for Your subscription of the
              Service that You have chosen, we will continue to attempt to bill and enable the subscription for a
              maximum period of 30 (thirty)consecutive days after the last successful bill collected. Should we be
              unable to collect any funds for a period of 30(thirty) consecutive days, You will be automatically
              unsubscribed from the Service.
            </p>
            <p>
              2.9 You give us consent to provide You with various messages about / based on Your interaction with
              the Service. These messages will be delivered to You in the form of text messages sent to Your
              mobile device using the mobile number identifying You as a user of the Service.
            </p>
            <p>
              These messages include, but are not limited to:
            </p>
            <p>
              2.9.1 periodic messages to encourage, or remind You to browse or access the Service;
              messages inviting You to share, voluntarily, data about Yourself or Your preferences;
            </p>
            <p>
              2.9.2 messages inviting You to try alternate versions of the Service based on Vodacom’s knowledge of
              the device You are using to access the Service;
            </p>
            <p>
              2.9.3 messages inviting You to rate the Service’s quality and Your opinion of Your experience of the
              Service as measured by the metric scale provided;<br>
              messages with Service content, including, but not limited to, confirmation of weekly results/ draws/
              picks, and;
            </p>
            <p>
              2.9.4 messages relating to the earning, notification, and servicing of rewards.
            </p>
            <p>
              2.10. You may opt out of the messages above except for the system messages which are mandatory in order
              for us to correctly administrate Your use of the Service. To opt out of the messages, You are advised
              to make use of the functionality on the Service settings to cancel messages, or alternatively, to send
              an opt-out message request to SMS STOP To 30881 or dial *135*18.
            </p>
            <p>
              You will not be charged for interactions that You will have with the Service via SMS. Data charges may
              apply for accessing the Service on website.
            </p>
            <p>
              2.11 You accept that the Service’s default language is English.
            </p>
            <p>
              By subscribing, You agree that You will be opted into receiving the messages mentioned above,
              which You may cancel at any time via the Service menu.
            </p>
            <p>
              2.12 You acknowledge that Your subscription information will be retained for use to allow You access
              to future iterations and new features of the Service, and that You will be engaged to continue using
              the Service as applicable for other functionality intended to be covered by the Service.
            </p>
            <p>
              2.13 You acknowledge that any subscriptions in error will be billed. For Your protection, we ask
              You to confirm all purchases twice.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            Rewards &amp; Prizes (Where applicable)
          </p>
          <div class="accordion-content">
            <p>
              2.14 If applicable and to be eligible for rewards from the Service, You acknowledge that You need to have
              an active subscription for the Service.<br>
              You acknowledge, understand, and agree that for Your subscription to the Service does not guarantee
              any prizes or rewards from the Service. We shall not have any liability for such loss or damage while
              using the Service.
            </p>
            <p>
              2.15 Where applicable, prizes may not be substituted or transferred. All taxes associated with the
              receipt or use of any prize is the sole responsibility of the winner. If any legal authority contests
              the awarding of a prize, we may, in our sole discretion, determine whether or not to award such a prize.
            </p>
            <p>
              Rewards can only be earned if You are a Vodacom customer. Any non-Vodacom customers will be unable
              to earn rewards due to their being active on a different network.
            </p>
            <p>
              2.16 Only the qualifying Vodacom mobile number that is subscribed to the Service, can earn rewards.
              In the event that You terminate Your Vodacom contract, You accept that You forfeit the use of any
              unused rewards by that point as well as the earning of future rewards from that point.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            Game rules or subscription rules
          </p>
          <div class="accordion-content">
            <p>
              2.17 The Service is exclusive to Vodacom customers only.<br>
              As a subscriber of the Service, You will be required to answer various trivia questions in the Service in
              various selected categories.
            </p>
            <p>
              2.18 Subscribers to the Service will be permitted to answer 12 (twelve) questions daily in certain
              categories.
              Users of the Service can answer 7 (seven) questions a day in a random category without needing to
              subscribe to the Service.<br>
              Data charges may apply for accessing the Service on website
            </p>
            <p>
              2.19 Any existing in-bundle usage and any other loaded data in Your account will not be consumed until
              the Service is depleted or expires. You can check Your bundle balance for the Service by dialing *135#,
              and choosing option 1 – balances or via My Vodacom App.<br>
              The subscription process (and terms and conditions) required for the Service shall at all times be
              applicable and shall constitute an agreement between You and the Partner Vodacom shall not be liable
              for any unavailability, faults, glitches, bugs, or technical issues with the Partner’s application and/or
              URL. Furthermore, Vodacom shall not be held liable for any damages or loss howsoever arising that You
              may suffer due to use of the Partner’s application and/or URL.
            </p>
            <p>
              2.20 No data pro-ration will occur.<br>
              If You use the applications outside of the Service, You will be charged as per Your existing tariff plan.
              If You follow a link outside the Partner’s application and/or URL, normal data rates will apply as per
              Your existing tariff plan.
            </p>
            <p>
              2.21 Your data bundle depletion will not occur for any banner advertising or in-stream advertising
              that may be present within the Service. When making use of the Service, certain applications and/or
              URL may contain third party advertisements, content, materials, and/or links to third party services
              and / or websites that are not owned or controlled by Vodacom. Vodacom assumes no responsibility for,
              the content, privacy policies or practices of any third-party websites or applications. In addition,
              Vodacom does not and cannot censor or edit the content of any third-party website. When You visit
              third party websites, Vodacom encourages You to read the terms and conditions and privacy policies
              of the other websites. By using the Service, You expressly release Vodacom from all liability arising
              from Your use of any third-party website or because of any third-party services.
            </p>
            <p>
              2.22 You cannot use the Services when You are roaming. Whilst roaming the use of an application and/or
              URL forming part of the Service, will be charged at Your existing tariff plan.<br>
              If You perform a price plan migration, whilst using You have an existing active Service, such Service
              shall be carried over to the new price plan.
            </p>
            <p>
              2.23 If You perform a price plan upgrade, You will be able to add the Service to Your upgraded price plan.<br>
              Vodacom reserves the right to terminate or change the Service at any time and for whatsoever reason
              provided
              the on-going subscription for the Service will not be affected.
            </p>
          </div>
        </div>

        <div class="accordion">
          <p class="paragraph uppercase">
            Your obligations and entitlements
          </p>
          <div class="accordion-content">
            <p>
              2.24 Vodacom and/or its appointed Partner reserves the right to determine what conduct it considers
              to be in violation of the rules of use or otherwise outside the intent or spirit of these Terms and
              Conditions or the Service itself. We reserve the right to act as a result, which may include, if
              relevant, suspending the Service, terminating Your registration, and prohibiting You from using the
              Service. Said suspension, termination and prohibition may occur with or without notice to You, and as
              a result may involve the loss of any benefits, privileges or earned items associated with Your use
              of the Service, and we are under no obligation to compensate You for any such losses or results.
            </p>
          </div>
        </div>
        <div class="accordion">
          <p class="paragraph uppercase">
            Limitation of liability
          </p>
          <div class="accordion-content">
            <p>
              2.25 You agree that Your use of the Service shall be at Your sole risk. To the fullest extent permitted
              by law, we and our officers, directors, employees, and agents disclaim all warranties, express or implied,
              in connection with the services and Your use thereof. We make no warranties or representations about
              the accuracy or completeness of the Service’s content or the content of any websites or services linked
              to these services and assume no liability or responsibility for any (i) errors, mistakes, or inaccuracies
              of content; (ii) personal injury or property damage of any nature whatsoever resulting from Your access
              to and use of the Service; (iii) any unauthorized access to or use of our servers and/or any and all
              personal
              information and/or financial information stored therein; (iv) any interruption or cessation of
              transmission
              to or from the Service; (v) any bugs, viruses, trojan horses or the like which may be transmitted to or
              through the Service by any third party; or (vi) any errors or omissions in any content or for any loss
              or damage of any kind incurred as a result of the use of any content posted, emailed, transmitted,
              or otherwise made available via the Service. We do not warrant, endorse, guarantee, or assume
              responsibility
              for any product or Service advertised or offered by a third party through the Service or any hyperlinked
              website or featured in any banner or other advertising, and we will not be a party to or in any way be
              responsible for monitoring any transaction between You and third-party providers of products or
              services.<br>
              <br>
              To the extent permitted by applicable law, in no event shall we or our subsidiaries and affiliates, and
              their
              respective officers, directors, owners, agents, employees, representatives and licensors be liable
              for any special, incidental, indirect, consequential, punitive or exemplary losses or damages
              whatsoever or for loss of profits (including, without limitation, damages for loss of revenue,
              loss of data, failure to realize expected savings, interruption of activities, or any other pecuniary
              or economic loss) and whether arising from breach of contract, damages (including negligence),
              strict liability or otherwise, arising out of the use of or inability to use the Service or its content,
              or any product or service described or provided through the Service.
            </p>
            <p>
              2.26 Save to the extent permitted by law, you agree to indemnify, defend and hold harmless Vodacom
              from and against any and all claims, demands, actions, liability, losses, costs and expenses
              (including legal fees and expenses) arising from or related to: (i) any use or alleged use of the
              Service by any other person, whether or not authorized by you; (ii) your breach of these Terms and
              Conditions, including any breach of your representations and warranties herein; and (iii) your violation
              of any law or (iv) your use of or inability to use the Service, the contents, any hyperlinked website,
              and any of the products and services made available on the Service thereof.
            </p>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(1)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">QUIZINC SEPTEMBER COMPETITION</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(1)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(1)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(1)">
        <div class="main-container col-12">
          <div class="accordion">
            <p class="paragraph uppercase">
              1 Introduction
            </p>
            <div class="accordion-content">
              <p>
                1.1 Vodacom (Pty) Ltd (“Vodacom”) and Fanclash South Africa (Pty) Ltd (“Partner”) are
                running a Competition where customers stand a chance of winning their share of
                R252 587.00 (two hundred and fifty-two thousand five hundred and eighty-seven
                Rand) worth of daily prizes by subscribing to and playing the Quizinc game (the
                “Competition”).
              </p>
            </div>
            <div class="accordion">
              <p class="paragraph uppercase text-center">
                IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS
              </p>
              <div class="accordion-content">
                <p class="paragraph uppercase">
                  PLEASE READ THESE TERMS AND CONDITIONS AND THE&nbsp;
                  <a href="https://www.vodacom.co.za/vodacom/terms/privacy-policy">PRIVACY POLICY</a>
                  CAREFULLY BEFORE PARTICIPATING IN THIS COMPETITION. BY ENTERING THE COMPETITION,
                  YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND CONDITIONS.&nbsp;IF YOU DO NOT AGREE,
                  PLEASE DO NOT ENTER THE COMPETITION OR SUBMIT ANY INFORMATION IN THIS REGARD. WE
                  RESERVE THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS AND CONDITIONS
                  FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS AND CONDITIONS ON THE VODACOM WEBSITE.
                  ANY SUCH UPDATES SHALL APPLY TO YOU AND YOUR ENTRY INTO THE COMPETITION AND YOU SHALL
                  BE DEEMED TO HAVE ACCEPTED SUCH CHANGES.
                </p>
                <p class="paragraph uppercase highlight-important">
                  ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS:
                </p>
              </div>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              2 Duration
            </p>
            <div class="accordion-content">
              <p>
                2.1 This Competition shall commence on 00h01 on 01 September 2024 to 23h59 on
                30 September 2024 (the “End Date”) (“the Competition Period”).
              </p>
            </div>
            <div class="accordion-content">
              <p>
                2.2 The duration of the Competition may be extended or curtailed at the discretion of
                Vodacom.
              </p>
            </div>
            <div class="accordion-content">
              <p>
                2.3 The competition is limited to the number of Vouchers and will continue until the supply
                is exhausted. Vodacom reserves the right to retract, amend or forgo the Competition at
                any point during the Competition Period.
              </p>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              3 Prizes
            </p>
            <div class="mt-2 accordion-content">
              3.1. Participants to the Competition stand a chance to win:
            </div>
            <div class="mt-2 accordion-content">
              3.1.1 one of the following 2 (two) prizes:
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.1 Grand Prizes based on points ranked leader board:
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.1.1 1st Place Prize – Samsung 65” Smart UHD TV worth R10 999.00 (ten thousand nine hundred and
              ninety-nine Rand);
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.1.2 2nd Place Prize – Bose SoundLink Flex Portable Bluetooth Speaker Stone Blue worth R4 489.00
              (four thousand four hundred and eighty-nine Rand);
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.1.3 3rd Place Prize - JBL Charge 5 Waterproof Portable Bluetooth Speaker worth R3 099.00 (three
              thousand and ninety-nine Rand)
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.1.4 The total value of the Grand Prize is R18 587.00 (eighteen thousand five hundred and
              eighty-seven Rand) (“the Grand Prize”)
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.2 their share of R234 000.00 (two hundred and thirty-four thousand Rand) worth of random lucky draw
              daily Shoprite/Checkers Vouchers, Vodacom Airtime and Vodacom Data, broken down as follows:
            </div>
            <div class="mt-2 accordion-content">
              3.1.1.2.1 One (1) Voucher daily, limited and allocated as shown in Table 1 below:
            </div>
            <div class="mt-2">
              Table 1:
              <table id="points-allocated">
                <thead>
                <tr>
                  <th>Leaderboard Position</th>
                  <th>Voucher Type</th>
                  <th>Voucher Value</th>
                  <th>Number of Monthly Vouchers</th>
                  <th>Total Value</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Random VIP</td>
                  <td>Shoprite/Checkers Voucher</td>
                  <td>R100.00</td>
                  <td>1000</td>
                  <td>R100 000.00</td>
                </tr>
                <tr>
                  <td>2nd – 10th Place</td>
                  <td>Vodacom Airtime</td>
                  <td>R10.00</td>
                  <td>10 000</td>
                  <td>R100 000.00</td>
                </tr>
                <tr>
                  <td>11th – 15th Place</td>
                  <td>Vodacom Data 1 GB</td>
                  <td>R85.00</td>
                  <td>400</td>
                  <td>R34 000.00</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-2 accordion-content">
              3.1.2 The Voucher entitles the participant to Shoprite &amp; Checkers Vouchers up to the
              maximum value of R100 (one hundred Rand), with the following conditions:
              <div>- The Voucher is valid for 3 (three) years.</div>
              <div>- The Voucher cannot be exchanged for cash or credit.</div>
              <div>- The Voucher cannot be replaced if lost or stolen.</div>
              <div>- This virtual Voucher can only be used in-store and cannot be used online.</div>
              <div>- The SMS should be kept until the Voucher has been redeemed.</div>
              <div>- Vouchers can be redeemed only once.</div>
              <div>- Vouchers cannot be redeemed at Usave mobile stores, MediRite Pharmacies
                or at Money Market counters.
              </div>
              <div>- No change will be given if the value of the purchase for which the Voucher is
                used is less than the value of the Voucher.
              </div>
              <div>- If the full value of the Voucher is not going to be redeemed in one transaction,
                the remaining value will be loaded onto a gift card in store.
              </div>
              <div>- Should the Voucher be lost or stolen, neither Shoprite &amp; Checkers nor its
                agents will be liable for any reimbursement of any kind.
              </div>
            </div>
            <div class="mt-2 accordion-content">
              3.1.2.1.1 For specific gift card usage terms, please read the Shoprite voucher terms and conditions found
              on this link: <a
                href="https://tickets.computicket.com/event/shoprite_group_virtual_grocery_vouchers/7145292/7165772/107235">https://tickets.computicket.com/event/shoprite_group_virtual_grocery_vouchers/71
              45292/7165772/107235</a>
            </div>
            <div class="mt-2 accordion-content">
              3.1.2.1.2 Vouchers will have a redemption validity of 7 (seven) days, winners must access
              the Quizinc service on: <a href="https://quizinc.co.za">quizinc.co.za</a> or by dialling *117*77# and
              claim the reward voucher to receive the Voucher code. Any qualifying Vouchers
              unredeemed at this
              time will be forfeited.
            </div>
            <div class="mt-2 accordion-content">
              3.1.3 The total value of the Prize is R252 587.00 (two hundred and fifty-two thousand five hundred and
              eighty-seven Rand) (“the Prize”)
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              4 Eligibility
            </p>
            <div class="accordion-content">
              <div class="mt-2">
                4.1. Only entries received during the Competition period will qualify to enter the
                Competition.
              </div>
              <div class="mt-2">
                4.2. The following requirements must be complied with during the Competition period in
                order to qualify for entry into the Competition:
              </div>
              <div class="mt-2">
                4.2.1. be a natural person;
              </div>
              <div class="mt-2">
                4.2.2. be at least 18 years and older;
              </div>
              <div class="mt-2">
                4.2.3. be a Vodacom prepaid, hybrid or post-paid subscriber during the Competition Period;
              </div>
              <div class="mt-2">
                4.2.4. use a SIM card that has been RICA registered; and
              </div>
              <div class="mt-2">
                4.2.5. Play the free Quizinc service, or subscribe to the Quizinc VIP service for R3.00 (three
                Rand) per day, R20.00 (twenty Rand) per week, or to the Quizinc All Access
                Pass service for R25.00 (twenty-five Rand) per month and log into the Quizinc
                service during the Competition Period. Participants are only able to play the Quizinc
                VIP game with additional questions on the days that the subscription is successfully
                billed. Participants can access the Quizinc and VIP additional questions service on
                any of the following platforms:
              </div>
              <div class="mt-2">
                4.2.5.1 direct URL link: <a href="https://quizinc.co.za">quizinc.co.za</a>; or
              </div>
              <div class="mt-2">
                4.2.5.2 by dialling USSD: *117*77#
              </div>
              <div class="mt-2">
                Once or if all the requirements of paragraphs 4.2.1 to 4.2.5 are met, entry into the
                Competition will be automatic.
              </div>
              <div class="mt-2">
                4.3 In the event that the registered user of the SIM Card or Vodacom account (i.e., the
                participant in the Competition) is not the person that is responsible for payment of the
                Vodacom account/ charges on the Vodacom account, then the Prize will be awarded
                to the person that is responsible for payment of the Vodacom account.
              </div>
              <div class="mt-2">
                4.4 Standard data costs apply as per the user’s Service Provider and existing price plan
                for data usage. Vodacom shall not be liable for any costs incurred by the winners for
                claiming any Prizes, where applicable.
              </div>
              <div class="mt-2">
                4.5 Entry to the Competition is free. Standard data costs apply as per the user’s Service
                Provider and existing price plan for data usage. Vodacom shall not be liable for any
                costs incurred by the winners for claiming any Prizes, where applicable.
              </div>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              5 Prize draw
            </p>
            <div class="accordion-content">
              5.1 Monthly Prize Winners will be randomly selected daily at midnight from all eligible
              entries who meet all the requirements set out in clause 3 and 4 above.
            </div>
            <div class="accordion-content mt-2">
              5.2 Grand Prize Winners will be selected according to their position on the leader board.
              Only the top 3 (three) participants on the leader board will be guaranteed one of the
              prizes based on their position on the leader board at the Competition Period End Date
              from all eligible entries who meet all the requirements set out in clause 3 and 4 above.
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              6 Winner Validation
            </p>
            <div class="accordion-content">
              <div class="mt-2">
                6.1. All the information provided or related to this Competition shall be managed, captured
                and approved by Vodacom and/or the Partner (where applicable).
              </div>
              <div class="mt-2">
                6.2. Vodacom and/or the Partner (where applicable) will use reasonable efforts to contact
                the winners via SMS 1 (one) day after they have been drawn as winners on the mobile
                number used by the participants to enter the Competition.
              </div>
              <div class="mt-2">
                6.3. The claim for any Prize will be subject to security, identification and validation, and
                Vodacom reserves the right to withhold any Prize until it is satisfied that the claim by a
                particular winner is valid. The selected winners must be able to identify themselves, in
                a manner determined by Vodacom including providing their South African identity
                number or a valid passport number, as the qualifying participants to the Competition
                and are required to comply with the validation procedure, determined by Vodacom, to
                claim any Prizes, failing which such winner will forfeit the Prize and the Prize will be
                awarded to the next selected qualifying participant.
              </div>
              <div class="mt-2">
                6.4. Failure to redeem the Prize within the stipulated 7 (seven) day period, will result in
                forfeiture of the Prize. Forfeited Vouchers will be returned to the Prize pool for future
                draws.
              </div>
              <div class="mt-2">
                6.5. Vodacom and/or the Partner (where applicable) shall attempt to contact the Grand
                Prize winners for a period of 05 (five) working days after their names have been drawn
                and verified as winners. The winners will be contacted during normal working hours,
                between 09:00 to 16:00. Winners will be advised of arrangements in respect of Prizes
                at the time of contacting the selected winners. Should a participant not be available on
                the contact details provided during the timeframe stipulated above or rejects, forfeits or
                declines acceptance of the Prize, that person&#39;s right to the Prize will be deemed to
                have been waived and the Prize will be forfeited. Vodacom reserves the right to then
                award the Prize to the next highest scoring participant on the leaderboard. The
                winners will be contacted within 7 (seven) working days after the End Date.
              </div>
              <div class="mt-2">
                6.6. Vodacom and/or the Partner (where applicable) shall attempt to contact the physical
                winners for a period of 05 (five) working days after their names have been drawn and
                verified as winners. The winners will be contacted during normal working hours, between 09:00 to 16:00.
                Winners will be advised of arrangements in respect of the
                physical Grand Prizes at the time of contacting the selected winners. Should a
                participant not be available on the contact details provided during the timeframe
                stipulated above or rejects, forfeits or declines acceptance of the Grand Prize, that
                person&#39;s right to the Grand Prize will be deemed to have been waived and the Grand
                Prize will be forfeited. Vodacom reserves the right to then award the Grand Prize to the
                next highest scoring participant on the leaderboard. The winners will be contacted
                within 7 (seven) working days after the End Date.
              </div>
              <div class="mt-2">
                6.7. In the event that Vodacom requires certain documentation to be submitted by a
                particular winner and same has not been received at the agreed cut-off date
                communicated to the winner, or no effort is made on the part of any of the winners to
                make special arrangements to meet the deadline set by Vodacom, such Prize will be
                forfeited. Vodacom then reserves the right to award the Prize to the finalist that is next
                in line.
              </div>
              <div class="mt-2">
                6.8. Vodacom and/or the Partner (where applicable) shall request the winners’ consent in
                writing to their image and/or likeness being used and published by Vodacom in
                connection with the Competition for a period of 12 (twelve) months after they have
                been announced as winners. The winners may decline the use of their image and/or
                likeness by Vodacom.
              </div>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              7 Prize Delivery
            </p>
            <div class="accordion-content">
              <div class="mt-2">
                7.1. To redeem the Vouchers, winners need to log on to the Quizinc service within
                the 7 (seven) day validity period by:
              </div>
              <div class="mt-2">
                7.1.1 using the direct URL link: <a href="http://quizinc.co.za/">http://quizinc.co.za/</a>, the winner
                will be presented with a
                popup that will allow them to view their reward; or
              </div>
              <div class="mt-2">
                7.1.2 dialling USSD: *117*77#, the winner will navigate the menu options to view and claim
                their Voucher.
              </div>
              <div class="mt-2">
                7.2. If a winner cannot accept a Prize for any reason whatsoever, the Prize will be forfeited.
              </div>
              <div class="mt-2">
                7.4. All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
                thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
                regard to the Prizes shall terminate.
              </div>
              <div class="mt-2">
                7.3. All Physical Prizes will be sent with a courier service to the winners’ physical
                addresses only. No Prizes will be delivered to a postal address.
              </div>
              <div class="mt-2">
                7.4. All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
                thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
                regard to the Prizes shall terminate.
              </div>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              8 Personal information
            </p>
            <div class="accordion-content">
              <p>
                8.1 User data, including personal information collected via the Competition will not be used for any
                other purpose than for execution of the Competition and will be processed in line with the Vodacom’s
                <a href="https://www.vodacom.co.za/vodacom/privacy-policy/terms?icmp=Home/Footer/PrivacyPolicy#eb0f6ca5-accordion-title--1053848991-1053848991">
                  Privacy Statement
                </a>
                which is incorporated into these terms and conditions. The Privacy Statement sets
                out how amongst other things Vodacom collects, use and shares subscribers personal information and
                how it protects subscriber’s privacy when processing their information.
              </p>
              <p>
                8.2 By continuing, a subscriber confirms that the Vodacom Privacy Statement has been read and
                understood.
                The latest Privacy Statement can be found on the Vodacom website at <a
                  href=" https://www.vodacom.co.za/vodacom/privacypolicy/terms?icmp=Home/Footer/PrivacyPolicy.">
                https://www.vodacom.co.za/vodacom/privacypolicy/terms?icmp=Home/Footer/PrivacyPolicy.
              </a>
              </p>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              9 General
            </p>
            <div class="accordion-content">
              <div class="mt-2">
                9.1. Participants may obtain a copy of the competition rules on the URL: <a
                  href="https://termsandconditions.quizinc.co.za/">https://termsandconditions.quizinc.co.za/
              </a>
              </div>
              <div class="mt-2">
                9.2. Nothing in these Terms and Conditions is intended to, or must be understood to,
                unlawfully restrict, limit or avoid any rights or obligations, as the case may be, created
                for either the participant or Vodacom in terms of the Consumer Protection Act, 68 of
                2008 (&quot;CPA&quot;).
              </div>
              <div class="mt-2">
                9.3. The Prize is not transferable and is not exchangeable for another Prize or cash and
                cannot be sold.
              </div>
              <div class="mt-2">
                9.4. The decision of Vodacom in respect of disputes arising out of this Competition shall be
                dealt with by Vodacom in terms of these Terms and Conditions. The decision of
                Vodacom in this regard shall be final and binding on the Parties and no
                correspondence will be entered into.
              </div>
              <div class="mt-2">
                9.5. Grand Prize Winners are only entitled to win 1 (one) Grand Prize each. However,
                where a participant has won but not accepted the Grand Prize within 7 (seven) days,
                the Grand Prize with be forfeited.
              </div>
              <div class="mt-2">
                9.6. In the event of a dispute with regard to any aspect of the Competition and/or the
                Terms and Conditions, Vodacom’s decision will be final, and binding and no
                correspondence will be entered into.
              </div>
              <div class="mt-2">
                9.7. Vodacom and/or the Partner may refuse to award a Prize if entry procedures or these
                Terms and Conditions have not been adhered to or if it detects any irregularities or
                fraudulent practices. Vodacom is entitled in its entire discretion to reject any participant
                for any reason and will not be obliged to notify participants that they have not been
                successful.
              </div>
              <div class="mt-2">
                9.8. Vodacom and/or the Partner and/or any other person or party associated with the
                Competition, their associated companies, agents, contractors and sponsors and any of
                its personnel involved in the Competition, shall not be liable whatsoever for any loss or
                damage incurred or suffered (including but not limited to direct or indirect or
                consequential loss), death or personal injury suffered or sustained arising from either the participant
                entering the Competition or from it claiming a Prize.
              </div>
              <div class="mt-2">
                9.9. Vodacom is not liable for any technical failures affecting participation and / or Prize
                redemption process of the Competition. In addition, neither Vodacom nor its agents
                shall be responsible for any loss or misdirected entries, including entries that were not
                received due to any failure of hardware, software, or other computer or technical
                systems affecting participating in and/or the Prize redemption process of the
                Competition.
              </div>
              <div class="mt-2">
                9.10. Vodacom, its directors, employees, agents, and distributors, are not responsible for
                any misrepresentation (whether written or verbal) in respect of any Prize nor in respect
                of any warranties, guarantees or undertakings given by any person other than
                Vodacom itself.
              </div>
              <div class="mt-2">
                9.11. If Vodacom elects in its sole and absolute discretion or is required by law to alter, vary
                or cancel any aspect of the Competition, it will have the right to terminate the
                Competition being offered, with immediate effect and upon written notice being
                published on the Vodacom website. In such event all participants hereby waive any
                rights which they may have against Vodacom and its associated companies, agents,
                contractors and/or sponsors.
              </div>
              <div class="mt-2">
                9.12. All information relating to the Competition which is published on any marketing
                material will form part of these Terms and Conditions. In the event of any conflict
                between such marketing material and these Terms and Conditions, these Terms and
                Conditions shall prevail.
              </div>
              <div class="mt-2">
                9.13. The Competition is also subject to, and must be read in conjunction with, Vodacom’s
                existing terms and conditions applicable to its website and mobile site respectively.,
                which terms and conditions are available on the Vodacom website.
              </div>
            </div>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(2)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">FAQ'S</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(2)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(2)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(2)">
        <div class="main-container">

          <div class="accordion">
            <div class="paragraph uppercase">1. How do I join the Quizinc mobile service for free?</div>
            <div class="accordion-content">
              <p>
                You can join Quizinc for free by dialing the USSD string *117*77# or by visiting <a
                  href="http://quizinc.co.za/">http://quizinc.co.za/</a> on your mobile web browser. You will receive
                engagement messages notifying you about the daily quiz and your progress.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">2. How can I subscribe to the premium service using my Vodacom mobile
              number?
            </div>
            <div class="accordion-content">
              <p>
                To subscribe to the premium service, you can either dial *117*77# or visit <a
                  href="http://quizinc.co.za/">http://quizinc.co.za/</a> and follow the prompts to subscribe. The
                premium
                service costs R 3/day or R 25/month.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">3. How do I unsubscribe from the Quizinc VIP service?</div>
            <div class="accordion-content">
              <p>
                To unsubscribe, you can dial *135*997#, select the services option, and then unsubscribe from Quizinc
                VIP. Alternatively, visit the Quizinc website or USSD string, go to the profile
                section, and follow the prompts to unsubscribe.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">4. How do I delete my profile and remove myself from the service?</div>
            <div class="accordion-content">
              <p>
                To delete your profile, visit the "My Profile" section on the Quizinc website or USSD string and follow
                the prompts. Deleting your profile will also unsubscribe you from the service.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">5. How are points awarded for answering questions and participating in the
              quiz?
            </div>
            <div class="accordion-content">
              <p>
                Points are awarded as follows:
                <br>• Subscribe to the service (once off): 1000 points
                <br>• First-time gameplay: 500 points
                <br>• Answering a question incorrectly (non-subscribed): 10 points
                <br>• Answering a question correctly (non-subscribed): 100 points
                <br>• Answering a question incorrectly (subscribed): 20 points
                <br>• Answering a question correctly (subscribed): 200 points
                <br>• Successful payment charge (subscribed): 500 points

              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">6. How are airtime prizes awarded?</div>
            <div class="accordion-content">
              <p>
                All points earned go into a daily draw. If your points win, you'll be awarded an airtime and/or data
                prize directly to your mobile number, or come to the platform to claim your
                Checkers Voucher. The draw takes place at 08h00 daily. There are R3 million in telco prizes to be won.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">7. Are there any additional promotions or offers on the platform?</div>
            <div class="accordion-content">
              From time to time, Quizinc will offer additional promotions on the platform. Keep an eye on your
              engagement messages and the website for updates on any new promotions.
            </div>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11  mt-2 mb-5 terms-conditions-container">
      <div @click="toggleDropdown(3)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">MORE HELP</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(3)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(3)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(3)">
        <strong>How to get more help:</strong>
        <div class="mt-2">Please send hello@thefanclash.com an email with your Mobile Number and Name as the
          Subject.
        </div>
        <div class="mt-2">In the email please explain your problem and make sure to include what platform (i.e Quizinc)
          you are having an issue on.
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
  </div>
</template>

<script>
import {environmentConfig} from "@/config/enviromentConfig";
import {mapState} from "vuex";

export default {
  name: "QuizInc",
  computed: {
    ...mapState(['encryptedMsisdn'])
  },
  data() {
    return {
      dropDownVisible: false,
      openDropdown: null,
      selectedDropDown: null,
    }
  },
  methods: {
    toggleDropdown(dropdown) {
      this.openDropdown = this.selectedDropDown === dropdown ? null : dropdown;
      this.selectedDropDown = this.openDropdown;
    },
    isDropdownOpen(dropdown) {
      return this.openDropdown === dropdown;
    },
    async playNowClicked() {
      let redirectUrl = environmentConfig.quizIncRedirectUrl;
      if (this.encryptedMsisdn) {
        redirectUrl += `/optin/service/4070?cid=100747&bc_id=63&acq-channel=app&optin-channel=wap&e-msisdn=${this.encryptedMsisdn}`;
      }
      window.open(redirectUrl, '_self');
    },
  },
}
</script>

<style scoped>
.vue-container {
  position: relative;
}

.header-text {
  font-size: 16px;
  font-weight: 800;
  z-index: 1;
}

.back-arrow {
  position: absolute;
  width: 80px;
  left: 5px;
  top: 10px;
}

.info-text {
  font-size: 14px;
  font-style: italic;
}

.header-text, .terms-conditions-dropdown-header {
  font-weight: 800;
}

.terms-conditions-dropdown-header {
  color: red;
}

.terms-conditions-text {
  font-size: 12px;
}

.accordion-content {
  color: #231f20;
  font-size: 11px;
}

.accordion {
  margin: 0 0 20px 0;
  padding: 0;
}

.paragraph.uppercase {
  text-transform: uppercase;
  font-weight: bold;
}

#points-allocated {
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
  margin-bottom: 20px;
}

table, th, td {
  border: 1px solid;
}

</style>
