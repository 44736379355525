<template>
  <div class="d-flex flex-column align-items-center mt-2 vue-container col-12">
    <img @click="this.$router.push('/')" class="back-arrow" src="../../assets/back-btn.png">
    <div class=" d-flex flex-column text-center align-items-center header-text mt-5 col-10">
      TERMS & CONDITIONS
    </div>
    <img class="game-logo col-6" src="../../assets/gameLogos/unlock-3-logo.png">
    <img @click="playNowClicked()" class="col-5" src="../../assets/play-now-btn.png">
    <div class="mt-2 col-9 info-text">
      WELCOME TO THE UNLOCK 3 TERMS AND CONDITIONS PAGE. PLEASE SELECT THE DROP DOWN THAT SUITS YOUR PURPOSES BELOW.
    </div>
    <div class="mt-2 header-text">
      UNLOCK 3
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(0)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">SERVICE TERMS</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(0)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(0)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(0)">
        <div class="main-container">
          <h1 class="heading-1">
            Unlock3 Word Game Terms and Conditions
          </h1>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                These terms and conditions govern the relationship between You (<strong>“You”</strong> or
                <strong>“Your”</strong>)
                and Vodacom Proprietary Limited and its affiliates (hereinafter <strong>“Vodacom”</strong> or <strong>“Us”</strong>
                or <strong>“We”</strong>) and Fanclash South Africa Pty Ltd (<strong>“Partner”</strong>) regarding
                Your use of the Unlock3 Word Game service and related features (the <strong>“Service”</strong>)
                (<strong>“Terms and Conditions”</strong>). Use of the Service is also governed by the
                <a href="https://www.vodacom.co.za/vodacom/terms/privacy-policy">Privacy Policy</a>
                which is incorporated herein by reference.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <h2 class="uppercase">
                Important provision: Acceptance of these Terms and Conditions
              </h2>
              <p>
                Please read these terms and conditions and the
                <a href="https://www.vodacom.co.za/vodacom/terms/privacy-policy">privacy policy</a>
                carefully before using the service or
                submitting information through the service. by using the service or submitting information through
                the service, you are agreeing to these terms and conditions. if you do not agree, please do not use
                this service or submit any information through the service. we reserve the right, at any time,
                to modify and update these terms and conditions from time to time by posting such updated terms and
                conditions on the vodacom website. any such updates shall apply to you and your use of the service
                and by continuing to use the service, you shall be deemed to have accepted such changes.
              </p>
            </div>
          </div>
          <div class="accordion">
            <h2 class="uppercase">
              SUBSCRIBE OR UNSUBSCRIBE TO THE SERVICE?
            </h2>
            <div class="accordion-content">
              <p>
                1.1. In order to subscribe the Service, You must:
              </p>
              <ul>
                <div>
                  1.1.1 dial USSD: *117*73#, press option 1 to select subscription, then press option 1 to confirm
                  the subscription or
                </div>
                <div>
                  1.1.2 visit Web: <a href="www.Quizinc.co.za/unlock3">www.Quizinc.co.za/unlock3</a>, click on the
                  subscribe button and confirm subscription.
                </div>
              </ul>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.2 You can also unsubscribe from the service by:
              </p>
              <ul>
                <div>
                  1.2.1 calling Vodacom Customer Care: 082 135
                </div>
                <div>
                  1.2.2 using Vodacom self-service channels; or
                </div>
                <div>
                  1.2.3 following the “cancel” prompts available within the Service menu
                </div>
                <div>
                  1.2.4 dialing USSD: *135*997#
                </div>
                <div>
                  1.2.5 visit WEB: <a href="www.Quizinc.co.za/unlock3">www.Quizinc.co.za/unlock3</a>
                </div>
              </ul>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.3 When cancelling/ unsubscribing from the Service, You accept that Your old profile and preferences
                will be retained for a 6 (six) month period and for no longer than 5 years (only if necessary), in
                order to facilitate a convenient experience should You choose to re-subscribe at a later stage within
                the 6 (six) month period.
              </p>
            </div>
          </div>
          <div class="accordion">
            <h2 class="uppercase">
              General
            </h2>
            <div class="accordion-content">
              <p>
                1.4 As a user of the Service, You will subscribe on a recurring basis making You a paying user and
                doing so accordingly binds You to these Terms and Conditions. As a subscribed user, You will be asked
                to submit personal information to us. We are committed to ensuring responsible practices in the
                collection, use and disclosure of Your personal information in accordance with applicable law and
                the
                <a href="https://www.vodacom.co.za/vodacom/privacy-policy/fantasy-league">Privacy Policy</a>
                incorporated by reference into and forms a binding part of these Terms and Conditions.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.5 By subscribing to the Service, You agree to the following additional rules upon successful initial
                registration for the Service:
              </p>
              <ul>
                <div>
                  1.5.1 You will be eligible to access all the content on the Service free of charge for 1 (one)
                  day (the <strong>“Free Trial Day”</strong>). For the avoidance of doubt, You will not be eligible
                  for another Free Trial Day if You cancel Your subscription and then re-register at a later stage.
                </div>
                <div>
                  1.5.2 After the Free Trial Day, You will be charged a subscription fee of ZAR3.00 (three Rand) per day
                  or ZAR20.00 (twenty Rand) per week (incl. VAT) (the “Fee”) for the relevant period that You remain
                  subscribed for the Service.
                </div>
              </ul>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.6 If You are a pre-paid or top-up user:
              </p>
              <ul>
                <div>
                  1.6.1 the Fee will be deducted from Your available airtime balance depending on the subscription
                  that you have chosen. You acknowledge that You must have enough airtime available to pay the
                  Fee to continue to use the Service;
                </div>
                <div>
                  1.6.2. if You fail to satisfy Your Fee obligation for 30 (thirty) consecutive days after the last
                  successful Fee was collected, Vodacom may at its sole discretion suspend or terminate Your
                  subscription
                  to the Service;
                </div>
                <div>
                  1.6.3 the Fee for each day of the month that You were subscribed to the Service for will be set
                  out in Your monthly statement for payment. If You do not pay Your monthly Fee set out in Your
                  monthly statement, Your subscription may be suspended;
                </div>
                <div>
                  1.6.4 before the expiry of the Free Trial Day, You may cancel Your subscription to the Service to
                  avoid being charged the Fee.
                </div>
                <div>
                  1.6.5 only one subscription is permitted per mobile number. Vodacom and non-Vodacom (where they
                  can do so) users within the Republic of South Africa may subscribe.
                </div>
              </ul>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.7 If You are a contract user, the Fee will be set out in Your monthly statement. If You do not pay
                your monthly Fee, Your subscription may be suspended by Vodacom in its discretion.
              </p>
            </div>
          </div>
          <div class="accordion">
            <h2 class="uppercase">
              Micro-billing
            </h2>
            <div class="accordion-content">
              <p>
                1.8 If Vodacom are unable to bill the full value of the subscription You have chosen for the Service,
                we may implement micro-billing. Micro billing occurs when You have subscribed to Service and You do
                not have sufficient funds in Your account (i.e., Your airtime balance) to pay for the subscription
                that You have chosen. Micro-billing will allow Vodacom to charge a discounted subscription or any
                reduced amount as we may determine from time to time dependent on the available balance until we can
                charge You the full amount. If we are successful in billing an amount, in part or in full for the
                end of the applicable period (depending on the subscription chosen), that billing record will stand
                as the completed billing for that particular period. Billing for the next period will revert back to
                attempting to bill the full stated price. Billing does not occur in arrears. Micro-billing shall
                be implemented in Vodacom’s sole and absolute discretion. You must have enough airtime available
                for payment of the subscription You have chosen for the Service to keep Your subscription in an
                active state. You acknowledge that in the event we are unable to bill any valid value for Your
                subscription of the Service that You have chosen, we will continue to attempt to bill and enable
                the subscription for a maximum period of 30 (thirty)consecutive days after the last successful bill
                collected. Should we be unable to collect any funds for a period of 30 (thirty) consecutive days,
                You will be automatically unsubscribed from the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.9 You give us consent to provide You with various messages about / based on Your interaction with
                the Service. These messages will be delivered to You in the form of text messages sent to Your
                mobile device using the mobile number identifying You as a user of the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.10 These messages include, but are not limited to:
              </p>
              <ul>
                <div>
                  1.10.1 periodic messages to encourage, or remind You to browse or access the Service;
                </div>
                <div>
                  1.10.2 messages inviting You to share, voluntarily, data about Yourself or Your preferences;
                </div>
                <div>
                  1.10.3 messages inviting You to try alternate versions of the Service based on Vodacom’s knowledge
                  of the device You are using to access the Service;
                </div>
                <div>
                  1.10.4 confirmation messages relating to Your subscribing for, or cancellation of, the Service;
                </div>
                <div>
                  1.10.5 messages inviting You to rate the Service’s quality and Your opinion of Your experience of
                  the Service as measured by the metric scale provided;
                </div>
                <div>
                  1.10.6 messages with Service content, including, but not limited to, confirmation of weekly
                  results/ draws/ picks, and;
                </div>
                <div>
                  1.10.7 messages relating to the earning, notification, and servicing of rewards.
                </div>
              </ul>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.11 You may opt out of the messages above except for the system messages which are mandatory in
                order for us to correctly administrate Your use of the Service. To opt out of the messages,
                You are advised to make use of the functionality on the Service settings to cancel messages,
                or alternatively, to send an opt-out message request to SMS STOP To 30881 or dial *135*18.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.12 You will not be charged for interactions that You will have with the Service via SMS. Data
                charges may apply for accessing the Service on the website.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.13 You accept that the Service’s default language is English.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.14 By subscribing, You agree that You will be opted into receiving the messages mentioned above,
                which You may cancel at any time via the Service menu.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.15 You acknowledge that Your subscription information will be retained for use to allow You access
                to future iterations and new features of the Service, and that You will be engaged to continue using
                the Service as applicable for other functionality intended to be covered by the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.16 You acknowledge that any subscriptions in error will be billed. For Your protection, we ask
                You to confirm all purchases twice.
              </p>
            </div>
          </div>
          <div class="accordion">
            <h2 class="uppercase">
              Rewards &amp; Prizes (where applicable)
            </h2>
            <div class="accordion-content">
              <p>
                1.17 If applicable and to be eligible for rewards from the Service, You acknowledge that You need
                to have an active subscription for the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.18 You acknowledge, understand, and agree that for Your subscription to the Service does not guarantee
                any prizes or rewards from the Service. We shall not have any liability for such loss or damage
                while using the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.19 Where applicable, prizes may not be substituted or transferred. All taxes associated with the
                receipt or use of any prize is the sole responsibility of the winner. If any legal authority
                contests the awarding of a prize, we may, in our sole discretion, determine whether or not to
                award such a prize.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.20 Rewards can only be earned if You are a Vodacom customer. Any non-Vodacom customers will be
                unable to earn rewards due to their being active on a different network.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.21 Only the qualifying Vodacom mobile number that is subscribed to the Service, can earn rewards.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.22 In the event that You terminate Your Vodacom contract, You accept that You forfeit the use of
                any unused rewards by that point as well as the earning of future rewards from that point.
              </p>
            </div>
          </div>
          <div class="accordion">
            <h2 class="uppercase">
              Game Rules or Subscription rules
            </h2>
            <div class="accordion-content">
              <p>
                1.23 You will be given 10 (ten) words that have been scrambled.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.24 Each word will be at least 3 letters long.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.25 You have to unscramble all 10 (ten) words in order to have played a valid game round.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.26 At the end of a round, the users’ cumulative time will be displayed on the daily leader board.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.27 The lower the cumulative time, the higher on the leader board the users will appear.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.28 Users are allowed multiple game play sessions per day.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.29 Customers can subscribe to the Promotional offer above by dialling *135# or by visiting
                the Vodacom website.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.30 Any existing in-bundle usage and any other loaded data in Your account will not be consumed until
                the Service is depleted or expires. You can check Your bundle balance for the Service by dialling
                *135#, and choosing option 1 – balances or via My Vodacom App.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.31 The subscription process (and terms and conditions) required for the Service shall at all times
                be applicable and shall constitute an agreement between You and the Partner. Vodacom shall not be
                liable for any unavailability, faults, glitches, bugs, or technical issues with the Partner’s
                application
                and/or URL. Furthermore, Vodacom shall not be held liable for any damages or loss howsoever arising that
                You may suffer due to use of the Partner’s application and/or URL.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.32 No data pro-ration will occur.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.33 If You use the applications outside of the Service, You will be charged as per Your existing
                tariff plan. If You follow a link outside the Partner’s application and/or URL, normal data rates
                will apply as per Your existing tariff plan.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.34 Your data bundle depletion will not occur for any banner advertising or in-stream advertising
                that may be present within the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.35 When making use of the Service, certain applications and/or URL may contain third party
                advertisements, content, materials, and/or links to third party services and / or websites
                that are not owned or controlled by Vodacom. Vodacom assumes no responsibility for, the content,
                privacy policies or practices of any third-party websites or applications. In addition,
                Vodacom does not and cannot censor or edit the content of any third-party website. When You visit
                third party websites, Vodacom encourages You to read the terms and conditions and privacy policies
                of the other websites. By using the Service, You expressly release Vodacom from all liability
                arising from Your use of any third-party website or because of any third-party services.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.36 You cannot use the Services when You are roaming. Whilst roaming the use of an application
                and/or URL forming part of the Service, will be charged at Your existing tariff plan.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.37 If You perform a price plan migration, whilst using You have an existing active Service, such
                Service shall be carried over to the new price plan.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.38 If You perform a price plan upgrade, You will be able to add the Service to
                Your upgraded price plan.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              1.39 Vodacom reserves the right to terminate or change the Service at any time and for whatsoever
              reason provided the on-going subscription for the Service will not be affected
              <p class="uppercase">
                Your Obligations and Entitlements
              </p>
              <p class="paragraph">
                Vodacom and/or its appointed Partner reserves the right to determine what conduct it considers to be in
                violation of the rules of use or otherwise outside the intent or spirit of these Terms and Conditions
                or the Service itself. We reserve the right to act as a result, which may include, if relevant,
                suspending the Service, terminating Your registration, and prohibiting You from using the Service.
                Said suspension, termination and prohibition may occur with or without notice to You, and as a result
                may involve the loss of any benefits, privileges or earned items associated with Your use of the
                Service,
                and we are under no obligation to compensate You for any such losses or results.
              </p>
            </div>
          </div>
          <div class="accordion">
            <h2 class="uppercase">
              Limitation of Liability
            </h2>
            <div class="accordion-content">
              <p>
                1.40. You agree that Your use of the Service shall be at Your sole risk. To the fullest extent permitted
                by law, we and our officers, directors, employees, and agents disclaim all warranties, express or
                implied, in connection with the services and Your use thereof. We make no warranties or representations
                about the accuracy or completeness of the Service’s content or the content of any websites or services
                linked to these services and assume no liability or responsibility for any (i) errors, mistakes, or
                inaccuracies of content; (ii) personal injury or property damage of any nature whatsoever resulting
                from Your access to and use of the Service; (iii) any unauthorized access to or use of our servers
                and/or any and all personal information and/or financial information stored therein; (iv) any
                interruption or cessation of transmission to or from the Service; (v) any bugs, viruses, trojan horses
                or the like which may be transmitted to or through the Service by any third party; or (vi) any errors
                or omissions in any content or for any loss or damage of any kind incurred as a result of the use of any
                content posted, emailed, transmitted, or otherwise made available via the Service. We do not warrant,
                endorse, guarantee, or assume responsibility for any product or Service advertised or offered by a
                third party through the Service or any hyperlinked website or featured in any banner or other
                advertising,
                and we will not be a party to or in any way be responsible for monitoring any transaction between You
                and
                third-party providers of products or services.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.41 To the extent permitted by applicable law, in no event shall we or our subsidiaries and affiliates,
                and their respective officers, directors, owners, agents, employees, representatives and licensors
                be liable for any special, incidental, indirect, consequential, punitive or exemplary losses or
                damages whatsoever or for loss of profits (including, without limitation, damages for loss of revenue,
                loss of data, failure to realize expected savings, interruption of activities, or any other pecuniary
                or economic loss) and whether arising from breach of contract, damages (including negligence), strict
                liability or otherwise, arising out of the use of or inability to use the Service or its content, or
                any product or service described or provided through the Service.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="accordion-content">
              <p>
                1.42 Save to the extent permitted by law, You agree to indemnify, defend and hold harmless Vodacom
                from and against any and all claims, demands, actions, liability, losses, costs and expenses (including
                legal fees and expenses) arising from or related to: (i) any use or alleged use of the Service by
                any other person, whether or not authorized by You; (ii) Your breach of these Terms and Conditions,
                including any breach of Your representations and warranties herein; and (iii) Your violation of any
                law or (iv) Your use of or inability to use the Service, the contents, any hyperlinked website, and
                any of the products and services made available on the Service thereof.
              </p>
            </div>
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(1)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">UNLOCK 3 SEPTEMBER COMPETITION</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(1)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(1)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(1)">
        <div class="main-container">
          <div class="accordion">
            <p class="">
              1. <a style="text-decoration: underline">Introduction:</a>
            </p>
            <div class="accordion-content">
              <p>
                1.1 Vodacom (Pty) Ltd (“Vodacom”) and Fanclash South Africa (Pty) Ltd (“Partner”) are
                running a Competition where customers stand a chance of winning their share of
                R20 250.00 (twenty thousand two hundred and fifty Rand) worth of monthly
                Shoprite/Checkers vouchers (“Vouchers”) and Vodacom Airtime by subscribing to and
                playing the Quizinc Unlock 3 game (the “Competition”).
              </p>
            </div>
          </div>
          <div class="accordion">
            <p class="paragraph uppercase">
              IMPORTANT PROVISION: ACCEPTANCE OF THESE TERMS AND CONDITIONS
            </p>
            <div class="accordion-content">
              <p class="paragraph uppercase">
                PLEASE READ THESE TERMS AND CONDITIONS AND THE&nbsp;
                <a href="https://www.vodacom.co.za/vodacom/terms/privacy-policy">PRIVACY POLICY</a>
                CAREFULLY BEFORE PARTICIPATING IN THIS COMPETITION. BY ENTERING THE COMPETITION,
                YOU ARE AGREEING TO BE BOUND BY THESE TERMS AND CONDITIONS.&nbsp;IF YOU DO NOT AGREE,
                PLEASE DO NOT ENTER THE COMPETITION OR SUBMIT ANY INFORMATION IN THIS REGARD. WE
                RESERVE THE RIGHT, AT ANY TIME, TO MODIFY AND UPDATE THESE TERMS AND CONDITIONS
                FROM TIME TO TIME BY POSTING SUCH UPDATED TERMS AND CONDITIONS ON THE VODACOM WEBSITE.
                ANY SUCH UPDATES SHALL APPLY TO YOU AND YOUR ENTRY INTO THE COMPETITION AND YOU SHALL
                BE DEEMED TO HAVE ACCEPTED SUCH CHANGES.
              </p>
              <p class="paragraph uppercase highlight-important">
                ALL PARTICIPANTS TO THE COMPETITION AGREE TO BE BOUND BY THE FOLLOWING TERMS AND CONDITIONS:
              </p>
            </div>
          </div>
          <div class="accordion">
            <p class="">
              2. <a style="text-decoration: underline">Duration:</a>
            </p>
            <div class="accordion-content">
              <p>
                2.1 This Competition shall commence on 00h01 on 01 September 2024 to 23h59 on
                30 September 2024 (the “End Date”) (“the Competition period”).
              </p>
              <p>
                2.2 The duration of the Competition may be extended or curtailed at the discretion of Vodacom.
              </p>
              <div class="mt-2">
                2.3. The competition is limited to the number of Vouchers and will continue until the supply
                is exhausted. Vodacom reserves the right to retract, amend or forgo the Competition at
                any point during the Competition period.
              </div>
            </div>
          </div>
          <div class="mt-2">
            3. <a style="text-decoration: underline">Prizes:</a>
          </div>
          <div class="mt-2">
            3.1. Participants to the Competition stand a chance to win:
          </div>
          <div class="mt-2">
            3.1.1. their share of R20 250.00 (twenty thousand two hundred and fifty Rand) worth of
            monthly Vouchers, broken down as follows:
          </div>
          <div class="mt-2">
            3.1.1.1. each participant stands a chance to win one (1) of three (3) Vouchers (&quot;the Prize&quot;),
            limited and allocated as shown in Table 1 below:
          </div>
          <div class="mt-2">
            Table 1:
            <table id="points-allocated">
              <thead>
              <tr>
                <th>Leaderboard Position</th>
                <th>Voucher Type</th>
                <th>Voucher Value</th>
                <th>Number of Monthly Vouchers</th>
                <th>Total Value</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>1st Place</td>
                <td>Shoprite/Checkers Voucher</td>
                <td>R100.00</td>
                <td>30</td>
                <td>R3 000.00</td>
              </tr>
              <tr>
                <td>2nd – 10th Place</td>
                <td>Vodacom Airtime</td>
                <td>R50.00</td>
                <td>270</td>
                <td>R13 500.00</td>
              </tr>
              <tr>
                <td>11th – 15th Place</td>
                <td>Vodacom Airtime</td>
                <td>R25.00</td>
                <td>150</td>
                <td>R3 750.00</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-2">
            3.1.2 The Voucher entitles the participant to Shoprite &amp; Checkers Vouchers up to the
            maximum value of R100 (one hundred Rand), with the following conditions:
            <div>- The Voucher is valid for 3 (three) years.</div>
            <div>- The Voucher cannot be exchanged for cash or credit.</div>
            <div>- The Voucher cannot be replaced if lost or stolen.</div>
            <div>- This virtual Voucher can only be used in-store and cannot be used online.</div>
            <div>- The SMS should be kept until the Voucher has been redeemed.</div>
            <div>- Vouchers can be redeemed only once.</div>
            <div>- Vouchers cannot be redeemed at Usave mobile stores, MediRite Pharmacies
              or at Money Market counters.
            </div>
            <div>- No change will be given if the value of the purchase for which the Voucher is
              used is less than the value of the Voucher.
            </div>
            <div>- If the full value of the Voucher is not going to be redeemed in one transaction,
              the remaining value will be loaded onto a gift card in store.
            </div>
            <div>- Should the Voucher be lost or stolen, neither Shoprite &amp; Checkers nor its
              agents will be liable for any reimbursement of any kind.
            </div>
          </div>
          <div class="mt-2">
            3.1.3 For specific Voucher usage terms, please read the Shoprite/Checkers Voucher terms and conditions found
            on this link:
            https://tickets.computicket.com/event/shoprite_group_virtual_grocery_vouchers/7145292/7165772/107235
          </div>
          <div class="mt-2">
            3.1.4 Vouchers will have a redemption validity period of 7 (seven) days, winners must access the Unlock 3
            service on: unlock3.co.za or by dialling *117*75# to claim the reward Voucher and receive the Voucher code.
            Any qualifying Vouchers unredeemed within 7 (seven) days of being won will be forfeited.
          </div>
          <div class="accordion">
            <div class="mt-2">
              4. <a style="text-decoration: underline">Eligibility:</a>
            </div>
            <div class="mt-2">
              4.1. Only entries received during the Competition period will qualify to enter the
              Competition.
            </div>
            <div class="mt-2">
              4.2. The following requirements must be complied with during the Competition period in
              order to qualify for entry into the Competition:
            </div>
            <div class="mt-2">
              4.2.1. be a natural person;
            </div>
            <div class="mt-2">
              4.2.2. be at least 18 years and older;
            </div>
            <div class="mt-2">
              4.2.3. be a Vodacom prepaid, hybrid or post-paid subscriber during the Competition Period;
            </div>
            <div class="mt-2">
              4.2.4. use a SIM card that has been RICA registered; and
            </div>
            <div class="mt-2">
              4.2.5. subscribe to the Quizinc Unlock 3 service for R3.00 (three Rand) per day,
              R20.00 (twenty Rand) per week, or to the Quizinc All Access Pass service for R25.00
              (twenty-five Rand) per month and log into the Stop the Clock service during the
              Competition period. Participants are only able to play the Stop the Clock game on the
              days that the subscription is successfully billed. Participants can access the Unlock 3 service on any of
              the following platforms:
            </div>
            <div class="mt-2">
              4.2.5.1 direct URL link: unlock3.co.za;
            </div>
            <div class="mt-2">
              4.2.5.2 main QuizInc URL link: www.quizinc.co.za and navigate to the Stop the Clock
              banner; or
            </div>
            <div class="mt-2">
              4.2.5.3 by dialling USSD: *117*75#
            </div>
            <div class="mt-2">
              Once or if all the requirements of paragraphs 4.2.1 to 4.2.5 are met, entry into the
              Competition will be automatic.
            </div>
            <div class="mt-2">
              4.3 In the event that the registered user of the SIM Card or Vodacom account (i.e., the
              participant in the Competition) is not the person that is responsible for payment of the
              Vodacom account/ charges on the Vodacom account, then the Prize will be awarded
              to the person that is responsible for payment of the Vodacom account.
            </div>
            <div class="mt-2">
              4.4 Standard data costs apply as per the user’s Service Provider and existing price plan
              for data usage. Vodacom shall not be liable for any costs incurred by the winners for
              claiming any Prizes, where applicable.
            </div>
          </div>
          <div class="accordion">
            <p class="">
              5. <a style="text-decoration: underline">Prizes Draw</a>
            </p>
            <div class="accordion-content">
              <p>
                5.1 Winners will be selected according to their position on the leader board daily at
                midnight from all eligible entries who meet all the requirements set out in clause 4
                above. Only the top 15 (fifteen) participants on the leader board will be guaranteed
                one of the Prizes based on their position on the leader board daily as set out in clause
                3 above.
              </p>
            </div>
          </div>
          <div class="accordion">
            <div class="mt-2">
              6. <a style="text-decoration: underline">Winner Validation</a>
            </div>
            <div class="mt-2">
              6.1. All the information provided or related to this Competition shall be managed, captured
              and approved by Vodacom and/or the Partner (where applicable).
            </div>
            <div class="mt-2">
              6.2. Vodacom and/or the Partner (where applicable) will use reasonable efforts to contact
              the winners via SMS 1 (one) day after they have been drawn as winners on the mobile
              number used by the participants to enter the Competition.
            </div>
            <div class="mt-2">
              6.3. The claim for any Prize will be subject to security, identification and validation, and
              Vodacom reserves the right to withhold any Prize until it is satisfied that the claim by a
              particular winner is valid. The selected winners must be able to identify themselves, in
              a manner determined by Vodacom including providing their South African identity
              number or a valid passport number, as the qualifying participants to the Competition
              and are required to comply with the validation procedure, determined by Vodacom, to
              claim any Prizes, failing which such winner will forfeit the Prize and the Prize will be
              awarded to the next selected qualifying participant.
            </div>
            <div class="mt-2">
              6.4. Failure to redeem the Prize within the stipulated 7 (seven) day period, will result in
              forfeiture of the Prize. Forfeited Vouchers will be returned to the Prize pool for future
              draws.
            </div>
            <div class="mt-2">
              6.5. In the event that Vodacom requires certain documentation to be submitted by a
              particular winner and same has not been received at the agreed cut-off date
              communicated to the winner, or no effort is made on the part of any of the winners to
              make special arrangements to meet the deadline set by Vodacom, such Prize will be
              forfeited. Vodacom then reserves the right to award the Prize to the finalist that is next
              in line.
            </div>
            <div class="mt-2">
              6.6. Vodacom and/or the Partner (where applicable) shall request the winners’ consent in
              writing to their image and/or likeness being used and published by Vodacom in
              connection with the Competition for a period of 12 (twelve) months after they have
              been announced as winners. The winners may decline the use of their image and/or
              likeness by Vodacom.
            </div>
          </div>
          <div class="accordion">
            <p class="mt-2">
              7. <a style="text-decoration: underline">Prize Delivery</a>
            </p>
            <div class="mt-2">
              7.1. To redeem the Vouchers, winners need to log on to the Stop the Clock service within
              the 7 (seven) day validity period by:
            </div>
            <div class="mt-2">
              7.1.1 using the direct URL link: www.stopclock.co.za, the winner will be presented with a
              popup that will allow them to view their reward; or
            </div>
            <div class="mt-2">
              7.1.2 dialling USSD: *117*76#, the winner will navigate the menu options to view and claim
              their Voucher.
            </div>
            <div class="mt-2">
              7.2. If a winner cannot accept a Prize for any reason whatsoever, the Prize will be forfeited.
            </div>
            <div class="mt-2">
              7.3. All risks and ownership of the Prizes shall pass to the winners on transfer/delivery
              thereof and hence all of Vodacom’s obligations regarding the Competition as well as in
              regard to the Prizes shall terminate.
            </div>
          </div>
          <div class="mt-2">
            8. <a style="text-decoration: underline">Personal information</a>
          </div>
          <div class="mt-2">
            8.1. User data, including personal information collected via the Competition will not be
            used for any other purpose than for execution of the Competition and will be
            processed in line with the Vodacom’s Privacy Statement which is incorporated into
            these terms and conditions. The Privacy Statement sets out how amongst other things
            Vodacom collects, use and shares subscribers personal information and how it
            protects subscriber’s privacy when processing their information.
          </div>
          <div class="mt-2">
            8.2. By continuing, a subscriber confirms that the Vodacom Privacy Statement has been
            read and understood. The latest Privacy Statement can be found on the Vodacom
            website at <a style="text-decoration: underline">https://www.vodacom.co.za/vodacom/privacy-
            policy/terms?icmp=Home/Footer/PrivacyPolicy.</a>
          </div>
          <div class="mt-2">
            9. <a style="text-decoration: underline">Prize Delivery</a>
          </div>
          <div class="mt-2">
            9.1. Participants may obtain a copy of the competition rules on the URL:
            https://termsandconditions.quizinc.co.za/
          </div>
          <div class="mt-2">
            9.2. Nothing in these Terms and Conditions is intended to, or must be understood to,
            unlawfully restrict, limit or avoid any rights or obligations, as the case may be, created
            for either the participant or Vodacom in terms of the Consumer Protection Act, 68 of
            2008 (&quot;CPA&quot;).
          </div>
          <div class="mt-2">
            9.3. The Prize is not transferable and is not exchangeable for another Prize or cash and
            cannot be sold.
          </div>
          <div class="mt-2">
            9.4. The decision of Vodacom in respect of disputes arising out of this Competition shall be
            dealt with by Vodacom in terms of these Terms and Conditions. The decision of
            Vodacom in this regard shall be final and binding on the Parties and no
            correspondence will be entered into.
          </div>
          <div class="mt-2">
            9.5. In the event of a dispute with regard to any aspect of the Competition and/or the
            Terms and Conditions, Vodacom’s decision will be final, and binding and no
            correspondence will be entered into.
          </div>
          <div class="mt-2">
            9.6. Vodacom and/or the Partner may refuse to award a Prize if entry procedures or these
            Terms and Conditions have not been adhered to or if it detects any irregularities or
            fraudulent practices. Vodacom is entitled in its entire discretion to reject any participant
            for any reason and will not be obliged to notify participants that they have not been
            successful.
          </div>
          <div class="mt-2">
            9.7. Vodacom and/or the Partner and/or any other person or party associated with the
            Competition, their associated companies, agents, contractors and sponsors and any of
            its personnel involved in the Competition, shall not be liable whatsoever for any loss or
            damage incurred or suffered (including but not limited to direct or indirect or
            consequential loss), death or personal injury suffered or sustained arising from either the participant
            entering the Competition or from it claiming a Prize.
          </div>
          <div class="mt-2">
            9.8. Vodacom is not liable for any technical failures affecting participation and / or Prize
            redemption process of the Competition. In addition, neither Vodacom nor its agents
            shall be responsible for any loss or misdirected entries, including entries that were not
            received due to any failure of hardware, software, or other computer or technical
            systems affecting participating in and/or the Prize redemption process of the
            Competition.
          </div>
          <div class="mt-2">
            9.9. Vodacom, its directors, employees, agents, and distributors, are not responsible for
            any misrepresentation (whether written or verbal) in respect of any Prize nor in respect
            of any warranties, guarantees or undertakings given by any person other than
            Vodacom itself.
          </div>
          <div class="mt-2">
            9.10. If Vodacom elects in its sole and absolute discretion or is required by law to alter, vary
            or cancel any aspect of the Competition, it will have the right to terminate the
            Competition being offered, with immediate effect and upon written notice being
            published on the Vodacom website. In such event all participants hereby waive any
            rights which they may have against Vodacom and its associated companies, agents,
            contractors and/or sponsors.
          </div>
          <div class="mt-2">
            9.11. All information relating to the Competition which is published on any marketing
            material will form part of these Terms and Conditions. In the event of any conflict
            between such marketing material and these Terms and Conditions, these Terms and
            Conditions shall prevail.
          </div>
          <div class="mt-2">
            9.12. The Competition is also subject to, and must be read in conjunction with, Vodacom’s
            existing terms and conditions applicable to its website and mobile site respectively.,
            which terms and conditions are available on the Vodacom website.
          </div>
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11 mt-2 terms-conditions-container">
      <div @click="toggleDropdown(2)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">FAQ'S</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(2)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(2)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(2)">
        <div class="main-container">

          <div class="accordion">
            <div class="paragraph uppercase">1. How can I join Unlock 3?</div>
            <div class="accordion-content">
              <p>
                You can join Unlock 3 by dialing the USSD string *117*75# or visiting the web platform at
                <a href="https://unlock3.co.za">www.unlock3.co.za.</a>
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">2. Do I incur any costs for playing Unlock 3 on the USSD channel?</div>
            <p class="accordion-content">
              No, users do not incur any costs for playing Unlock 3 on the USSD channel outside of their subscription.
            </p>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">3. Will I incur data costs if I play Unlock 3 on the web product?</div>
            <div class="accordion-content">
              <p>
                Yes, customers will incur data costs in line with their current plan and package.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">4. How much does it cost to join Unlock 3?</div>
            <div class="accordion-content">
              <p>
                Users can join the service for R3 per day or join the Quizinc bundle of R 25 per month, which will be
                added to their Vodacom bill.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">5. How can I manage my Unlock 3 service messages?</div>
            <div class="accordion-content">
              <p>
                Users can manage their service messages within the My Profile section of the game or by replying with
                the message STOP.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">6. How can I view or unsubscribe from Unlock 3?</div>
            <div class="accordion-content">
              <p>
                Users can view or unsubscribe from Unlock 3 by visiting *135*997# or visiting the platform and choosing
                the manage package menu on the burger menu.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">7. How can I access the Unlock 3 platform?</div>
            <div class="accordion-content">
              <p>
                Users will have access to the Unlock 3 platform if they are subscribed to the Unlock 3 service or the
                Quizinc bundle.
              </p>
            </div>
          </div>


          <div class="accordion">
            <div class="paragraph uppercase">8. What kind of game is Unlock 3?</div>
            <div class="accordion-content">
              <p>
                Unlock 3 is a quick and fun to play word game. Users need to make the correct word out of three, four
                and five letter puzzles.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">9. How many words are in each game session?</div>
            <div class="accordion-content">
              <p>
                Each game session is made up of 10 words.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">10. How does the leaderboard work in Unlock 3?</div>
            <div class="accordion-content">
              <p>
                The user who gets all the words correct in the shortest time will place highest on the leaderboard. The
                top 15 users on the leaderboard will win. 1st place wins a R100 Checkers
                Voucher, 2nd – 10th win R50 airtime each, and 11th – 15th each win R25.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">11. Can I play the Unlock 3 puzzle game more than once a day?</div>
            <div class="accordion-content">
              <p>
                Yes, users can play the puzzle game as many times as they would like daily.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">12. When does the leaderboard run?</div>
            <div class="accordion-content">
              <p>
                The leaderboard runs from 12h00 daily with the last entry at 11h59h59.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">13. What happens to gameplay that is underway when the leaderboard
              resets?
            </div>
            <div class="accordion-content">
              <p>
                Any gameplay that is underway when the leaderboard resets will be entered into the following day's
                promotion.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">14. What is the total prize pool for Unlock 3?</div>
            <div class="accordion-content">
              <p>
                The total prize pool is R 21000.00 monthly or R 252 000.00 annually. The total number of prizes annually
                will be 5475.
              </p>
            </div>
          </div>

          <div class="accordion">
            <div class="paragraph uppercase">15. When does the first promotion on the platform start and end?</div>
            <div class="accordion-content">
              <p>
                The total prize pool is R 21000.00 monthly or R 252 000.00 annually. The total number of prizes annually
                will be 5475.
              </p>
            </div>
          </div>

        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
    <div class="d-flex flex-column col-11  mt-2 mb-5 terms-conditions-container">
      <div @click="toggleDropdown(3)" class="d-flex flex-row">
        <div class="col-11 terms-conditions-dropdown-header">MORE HELP</div>
        <div class="col-1">
          <img v-if="!isDropdownOpen(3)" class="col-5" src="../../assets/plus-btn.png">
          <img v-if="isDropdownOpen(3)" class="col-5" src="../../assets/minus-btn.png">
        </div>
      </div>
      <div class="terms-conditions-text mt-1" v-if="isDropdownOpen(3)">
        <strong>How to get more help:</strong>
        <div class="mt-2">Please send hello@thefanclash.com an email with your Mobile Number and Name as the
          Subject.
        </div>
        <div class="mt-2">In the email please explain your problem and make sure to include what platform (i.e Quizinc)
          you are having an issue on.
        </div>
      </div>
      <img class="mt-3 col-12" src="../../assets/divider-line.png">
    </div>
  </div>
</template>

<script>
import {environmentConfig} from "@/config/enviromentConfig";
import {mapState} from "vuex";

export default {
  name: "UnlockThree",
  computed: {
    ...mapState(['encryptedMsisdn'])
  },
  data() {
    return {
      dropDownVisible: false,
      openDropdown: null,
      selectedDropDown: null,
    }
  },
  methods: {
    toggleDropdown(dropdown) {
      this.openDropdown = this.selectedDropDown === dropdown ? null : dropdown;
      this.selectedDropDown = this.openDropdown;
    },
    isDropdownOpen(dropdown) {
      return this.openDropdown === dropdown;
    },
    async playNowClicked() {
      let redirectUrl = environmentConfig.unlockThreeRedirectUrl;
      if (this.encryptedMsisdn) {
        redirectUrl += `/?e-msisdn=${this.encryptedMsisdn}`;
      }
      window.open(redirectUrl, '_self');
    },
  },
}
</script>

<style scoped>
.vue-container {
  position: relative;
}

.header-text {
  font-size: 16px;
  font-weight: 800;
  z-index: 1;
}

.back-arrow {
  position: absolute;
  width: 80px;
  left: 5px;
  top: 10px;
}

.info-text {
  font-size: 14px;
  font-style: italic;
}

.header-text, .terms-conditions-dropdown-header {
  font-weight: 800;
}

.terms-conditions-dropdown-header {
  color: red;
}

.terms-conditions-text {
  font-size: 12px;
}

.accordion-content {
  color: #231f20;
  font-size: 11px;
}

.accordion {
  margin: 0 0 20px 0;
  padding: 0;
}

.heading-1 {
  align-items: center;
  color: #231f20;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}

.uppercase, h2 {
  font-size: 14px
}

.accordion-content {
  color: #231f20;
  font-size: 11px;
}

.accordion {
  margin: 0 0 20px 0;
  padding: 0;
}

.paragraph.uppercase {
  text-transform: uppercase;
  font-weight: bold;
}

table, th, td {
  border: 1px solid;
}

</style>
